import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import imageSrc from "../../images/kxjwhrbj.jpg";

const BlogPost = (props) => {

    var dateTime = "2022-10-26 12:00:00";
    var title = "Hellow-World";
    var comments= "0";
    var linkToPost = "#";

    var text = "Sorry for the lame title but as a programmer my lack of originality has forced me to title every new project this way. Anyways this is my attempt " +
    "to create a blog which I am sure will be increaseing in complexity in the future. For now I'm currently using react as my front end (I know its overkill " +
    "for a currently pretty static website) and bootstrap for prettiness. In the future I want to add some authorization for me to log in and make blog posts and "+
    "for other users to like and comment on blog posts too. Of course I am going to need a back end and a database to do that to and that is where my react framework " +
    "will come in handy with more dynamic pages. I am not entirely sure what I will add to this project in the future but for now I will play it by ear and expand on it " +
    "going forward. As for the name of this blog don't worry you aren't an idiot for reading (I hope) but the man who is supporting it sure is :p.";

    return (
        <div className='container text-white'>
            <div className='col-sm-12'>
                <div className="row border">
                    <div className="col-8 col-sm-2">
                        <h5>{title}</h5>
                    </div>
                </div>
            </div>
            <div className='row border'>
                <div className="col-sm-3">
                    <img src={imageSrc} className="img-thumbnail" alt='Thumbnail'/>
                </div>
                <div className="col-sm-9">
                    <p>{text} <a href={linkToPost}>Read More...</a></p>
                </div>
            </div>
            <div className='col-sm-12'>
                <div className="row border">
                    <div className="col-8 col-sm-3">
                        <span >{dateTime}</span>
                    </div>
                    <div className="col-8 col-sm-6">
                        <span >{comments} Comments</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogPost;