import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const NavMenu = () =>{
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <a className="navbar-brand" href="/blog">Blog For Idiots</a>

            <div className="navbar-collapse" id="navbarTogglerDemo03">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item active">
                        <a className="nav-link" href="/about">About</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/blog">Blog</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://github.com/Tbauer426" target="_blank" rel="noreferrer">GitHub</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contactus">Contact Us</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default NavMenu;