import React from 'react';
import BlogPost from './BlogPost';

const BlogPostMainPage = (props) => {
    return (
        <div className="App">
            <div className='Title'>
                <h1>A Blog For Idiots</h1>
                <h5>By an idiot (Tyler Bauer)</h5>
            </div>
            <BlogPost/>
        </div>
    );
}

export default BlogPostMainPage;