import React from 'react';

const ContactPage = (props) => {
    return (
        <div className="App">
            <div className='Title'>
                <h1>Contact Us</h1>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <p>
                                Email: bauertyler11@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;