import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import BlogPostMainPage from './components/blogPosts/BlogPostMainPage';
import AboutMainPage from './components/about/AboutMainPage';
import ContactPage from './components/about/ContactPage';

function App() {
  document.body.style = 'background: black;';
  return (
       <BrowserRouter>
          <Routes>
            <Route path="/" element={<AboutMainPage/>} />
            <Route path="/about" element={<AboutMainPage/>} />
            <Route path="/blog" element={<BlogPostMainPage/>} /> 
            <Route path="/contactus" element={<ContactPage/>} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
