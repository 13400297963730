import React from 'react';
import imageSrc from '../../images/IMG_6701.jpg';

const AboutMainPage = (props) => {
    return (
        <div className="App">
            <div className='Title'>
                <h1>About</h1>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src={imageSrc} className="img-fluid img-thumbnail" alt='This is supposed to be me but its not here :('/>
                        </div>
                        <div className='col-sm-6'>
                            <p>
                                Tyler Bauer is a software engineer currently residing in San Diego mainly developing desktop applications using C#, SQL and WPF. 
                                In the past he has developed applications using HTML, CSS, JS and the JS library D3. He has also developed this website using react.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMainPage;